import { FC, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { MobXProviderContext, observer } from 'mobx-react';

import { Button, Form, FormButtons, FormFieldType, IMetadataField } from '../../../../components';
import { CloseButton } from '../../../../components/buttons/close-button';
import { PurposeType } from '../../../../constants';
import { Stores } from '../../../../stores';
import { message, useConfirm } from '../../../../utils';
import { IPlotting } from '../../../../view-models';

import { PlottingFile } from './plotting-file';

export const Plotting: FC = observer(() => {
  const { plottingStore: store, packageStore } = useContext(MobXProviderContext) as Stores;
  const { confirm } = useConfirm();
  const { packageId } = useParams();

  const [isFinishing, setIsFinishing] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isSaving, setIsSaving] = useState(false);

  const {
    control,
    formState: { errors, isDirty },
    handleSubmit,
    setValue,
    reset,
    watch,
  } = useForm<Partial<IPlotting>>({
    values: {},
  });

  const isWorkCompleted = watch('isWorkCompleted');

  const loadFormData = useCallback(
    async (packageId?: string) => {
      if (packageId) {
        try {
          setIsLoading(true);
          const data = await store.fetch(packageId);
          reset(data);
        } finally {
          setIsLoading(false);
        }
      }
    },
    [reset, packageId, setIsLoading]
  );

  useEffect(() => {
    loadFormData(packageId);

    return reset;
  }, [loadFormData, packageId, reset]);

  const onChangeFile = useCallback(
    (fileIdentity?: string) => {
      setValue('fileIdentity', fileIdentity ?? null, { shouldDirty: true });
    },
    [setValue]
  );

  const metadata: IMetadataField[] = useMemo(
    () => [
      {
        name: 'fileIdentity',
        label: 'Плоттинг',
        type: FormFieldType.CUSTOM,
        template: (props) => <PlottingFile value={props.value} disabled={isWorkCompleted} onChange={onChangeFile} />,
      },
    ],
    [setValue, packageId, store, isWorkCompleted]
  );

  const onSubmit: SubmitHandler<Partial<IPlotting>> = useCallback(
    async (data) => {
      if (packageId) {
        try {
          setIsSaving(true);
          const res = await store.save(packageId, data as IPlotting);
          reset(res);
          // Для того чтобы обновить информацию (статус) в левом зелленом блоке
          packageStore.fetch(packageId, PurposeType.ForExpertise);
          message.success('Данные сохранены. Вы можете вернуться к заполнению рабочего листа позже');
        } finally {
          setIsSaving(false);
        }
      }
    },
    [store, packageId, reset]
  );

  const onClickFinalize = useCallback(async () => {
    if (packageId) {
      try {
        setIsFinishing(true);
        const isConfirmed = await confirm({
          title: 'Завершение исследования',
          content: (
            <pre>
              Вы уверены, что хотите завершить исследование?
              <br />
              После этого редактирование блока "Плоттинг" будет недоступно
            </pre>
          ),
        });

        if (!isConfirmed) {
          return;
        }

        const completedResearch = await store.completeResearch(packageId);
        reset(completedResearch);
        message.success('Исследование завершено!');
      } finally {
        setIsFinishing(false);
      }
    }
  }, [packageId, store, reset]);

  return (
    <Form
      className={'worksheet__diagnostic'}
      metadata={metadata}
      control={control}
      errors={errors}
      disabled={isWorkCompleted}
      isLoading={isLoading}
    >
      <FormButtons>
        <CloseButton />
        {!isWorkCompleted && (
          <>
            <Button.Contained onClick={handleSubmit(onSubmit)} disabled={!isDirty} isLoading={isSaving}>
              Сохранить
            </Button.Contained>
            <Button.Outlined disabled={!store.data} isLoading={isFinishing} onClick={onClickFinalize}>
              Завершить исследование
            </Button.Outlined>
          </>
        )}
      </FormButtons>
    </Form>
  );
});
