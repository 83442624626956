import { FormFieldType } from '../../../../components';
import { minValidator } from '../../../../utils';

export const metadata = [
  {
    name: 'minDiameterMm',
    label: 'Диаметр Dmin',
    type: FormFieldType.NUMBER,
    rules: {
      required: 'Обязательно к заполнению',
      validate: {
        min: minValidator(0),
      },
      max: {
        value: 100,
        message: 'Макс: 100',
      },
    },
  },
  {
    name: 'maxDiameterMm',
    label: 'Диаметр Dmax',
    type: FormFieldType.NUMBER,
    rules: {
      required: 'Обязательно к заполнению',
      validate: {
        min: minValidator(0),
      },
      max: {
        value: 100,
        message: 'Макс: 100',
      },
    },
  },
  {
    name: 'totalHeightMm',
    label: 'Общая высота, мм',
    type: FormFieldType.NUMBER,
    rules: {
      required: 'Обязательно к заполнению',
      validate: {
        min: minValidator(0),
      },
      max: {
        value: 100,
        message: 'Макс: 100',
      },
    },
  },
  {
    name: 'totalDepthPt',
    label: 'Общая высота, %',
    type: FormFieldType.NUMBER,
    rules: {
      required: 'Обязательно к заполнению',
      validate: {
        min: minValidator(0),
      },
      max: {
        value: 100,
        message: 'Макс: 100',
      },
    },
  },
  {
    name: 'tableSizePt',
    label: 'Размер площадки, %',
    type: FormFieldType.NUMBER,
    rules: {
      required: 'Обязательно к заполнению',
      validate: {
        min: minValidator(0),
      },
      max: {
        value: 100,
        message: 'Макс: 100',
      },
    },
  },
  {
    name: 'crownHeightPt',
    label: 'Высота короны, %',
    type: FormFieldType.NUMBER,
    rules: {
      validate: {
        min: minValidator(0),
      },
      max: {
        value: 100,
        message: 'Макс: 100',
      },
    },
  },
  {
    name: 'pavilionDepthPt',
    label: 'Глубина павильона, %',
    type: FormFieldType.NUMBER,
    rules: {
      validate: {
        min: minValidator(0),
      },
      max: {
        value: 100,
        message: 'Макс: 100',
      },
    },
  },
  {
    name: 'girdleThicknessPt',
    label: 'Высота рундиста, %',
    type: FormFieldType.NUMBER,
    rules: {
      validate: {
        min: minValidator(0),
      },
      max: {
        value: 100,
        message: 'Макс: 100',
      },
    },
  },
  {
    name: 'crownAngleDg',
    label: 'Угол короны, град',
    type: FormFieldType.NUMBER,
    rules: {
      validate: {
        min: minValidator(0),
      },
      max: {
        value: 90,
        message: 'Макс: 90',
      },
    },
  },
  {
    name: 'pavilionAngleDg',
    label: 'Угол павильона, град',
    type: FormFieldType.NUMBER,
    rules: {
      validate: {
        min: minValidator(0),
      },
      max: {
        value: 90,
        message: 'Макс: 90',
      },
    },
  },
  {
    name: 'lowerHalfLengthPt',
    label: 'Высота клиньев низа, %',
    type: FormFieldType.NUMBER,
    rules: {
      validate: {
        min: minValidator(0),
      },
      max: {
        value: 100,
        message: 'Макс: 100',
      },
    },
  },
  {
    name: 'starLengthPt',
    label: 'Высота клиньев верха, %',
    type: FormFieldType.NUMBER,
    rules: {
      validate: {
        min: minValidator(0),
      },
      max: {
        value: 100,
        message: 'Макс: 100',
      },
    },
  },
];
