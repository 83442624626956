import { FC, useCallback, useContext, useEffect, useMemo } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { reaction } from 'mobx';
import { MobXProviderContext, observer } from 'mobx-react';

import {
  Button,
  Form,
  FormButtons,
  FormFieldType,
  FormWrapper,
  IMetadataField,
  InfoBlock,
  PageWrapper,
  Panel,
  QrCodeButton,
  UnderlinedHeader,
} from '../../components';
import { PackageState, packageStateMap, PackageType } from '../../constants';
import { Stores } from '../../stores';
import { message, useConfirm } from '../../utils';
import { IPackage } from '../../view-models';

import { CertificateButton } from './certificate-button';
import { getMultipleStonesMetadata } from './get-multiple-stones-metadata';
import { getSingleStoneMetadata } from './get-single-stone-metadata';
import { Worksheet } from './worksheet';

import './package-form.css';

export const PackageForm: FC = observer(() => {
  const { packageStore: store, dictionaryStore } = useContext(MobXProviderContext) as Stores;
  const navigate = useNavigate();
  const { orderId, packageId } = useParams();
  const { confirm } = useConfirm();

  const isCreateMode: boolean = useMemo(() => {
    return packageId === 'create';
  }, [packageId]);

  const isEditMode: boolean = useMemo(() => {
    return !!packageId && packageId !== 'create';
  }, [packageId]);

  const {
    clearErrors,
    control,
    formState: { errors, isDirty },
    handleSubmit,
    reset,
    setValue,
    watch,
  } = useForm<Partial<IPackage>>({
    values: {
      state: PackageState.InWork,
    },
  });

  const state = watch('state');
  const packageType = watch('type');
  const additionalWorkScope = watch('additionalWorkScope');

  const isDisabledForm: boolean = useMemo(() => {
    return !!state && state !== PackageState.InWork;
  }, [state]);

  useEffect(() => {
    const dataObserver = reaction(
      () => store.data,
      (pack) => {
        if (pack) {
          reset({ ...pack });
        }
      },
      {
        fireImmediately: true, // чтобы reaction срабатывал и в первый раз
        equals: (prev: IPackage | null, next: IPackage | null): boolean => prev === next,
        delay: 500,
      }
    );

    return () => {
      dataObserver?.();
    };
  }, [store, reset]);

  useEffect(() => {
    if (isEditMode) {
      store.fetch(packageId);
    }
  }, [store, packageId, isEditMode]);

  useEffect(() => {
    const type = dictionaryStore.getFirstValue('consul/package-type') as PackageType;
    const cut = dictionaryStore.getFirstValue('consul/cut');
    // const certificationDocumentType = dictionaryStore.getFirstValue('consul/certification-document-type');
    const certificationDocumentType = 'SmallCertificateOfConformity';
    const container = dictionaryStore.getFirstValue('consul/container');

    if (isCreateMode) {
      reset({
        type,
        cut: `${cut}`,
        certificationDocumentType: `${certificationDocumentType}`,
        container: `${container}`,
      });
    }
  }, [
    isCreateMode,
    dictionaryStore.dictionaries.get('consul/package-type'),
    dictionaryStore.dictionaries.get('consul/cut'),
    dictionaryStore.dictionaries.get('consul/container'),
  ]);

  useEffect(() => {
    clearErrors('customEngravingText');
  }, [additionalWorkScope?.has('CustomEngraving'), clearErrors]);

  const changePackageTypeHandler = useCallback((event: any) => {
    setValue(event.target.name, event.target.value, { shouldValidate: true, shouldDirty: true });

    if (event.target.value === PackageType.MultipleStones) {
      setValue('additionalWorkScope', undefined, { shouldDirty: true });
      setValue('customEngravingText', undefined, { shouldDirty: true });
      setValue('certificationDocumentType', 'SmallCertificateOfConformity', { shouldDirty: true });
      setValue('container', 'PaperBag', { shouldDirty: true });
    }

    if (event.target.value === PackageType.SingleStone) {
      setValue('quantityFact', undefined, { shouldDirty: true });
      setValue('quantityPlan', undefined, { shouldDirty: true });
    }
  }, []);

  const metadata: IMetadataField[] = useMemo(
    () => [
      {
        name: 'type',
        label: 'Тип пакета',
        type: FormFieldType.SELECT,
        url: 'consul/package-type',
        rules: { required: 'Обязательно к заполнению' },
        disabled: isEditMode,
        onChange: changePackageTypeHandler,
      },
      ...(packageType === PackageType.SingleStone ? getSingleStoneMetadata(additionalWorkScope) : getMultipleStonesMetadata()),
    ],
    [isEditMode, packageType, changePackageTypeHandler, additionalWorkScope]
  );

  const onSubmit: SubmitHandler<Partial<IPackage>> = useCallback(
    async (newPackage) => {
      if (!orderId) {
        return;
      }

      let packageId = newPackage?.packageId ?? null;
      if (isEditMode) {
        const isConfirmed = await confirm({
          title: 'Подтвердите',
          content: 'Вы уверены, что хотите изменить свойства пакета?',
        });

        if (!isConfirmed) {
          return;
        }

        await store.update(newPackage);
        message.success('Пакет успешно сохранен');
      } else {
        packageId = await store.create(orderId, newPackage);
        message.success('Пакет успешно зарегистрирован!');
      }

      if (packageId) {
        navigate(`/orders/${orderId}/packages/${packageId}`, { replace: true });
      }
    },
    [store, orderId, isEditMode]
  );

  const headerText = useMemo(() => {
    if (packageId === 'create') {
      return 'Новый пакет';
    }

    if (store.data?.number === undefined) {
      return 'У пакета нет номера';
    }

    return `Пакет № ${store.data.number}`;
  }, [packageId, store.data?.number]);

  const stateInfo = useMemo(() => {
    return [
      {
        label: 'Статус',
        value: packageStateMap.get(state ?? PackageState.InWork),
      },
    ];
  }, [state]);

  const onClosePackage = useCallback(async () => {
    if (packageId) {
      const isConfirmed = await confirm({
        title: 'Закрытие пакета',
        content: (
          <pre>
            Вы уверены, что хотите закрыть пакет?
            {state !== PackageState.CertificatePrinted && (
              <>
                <br />
                Для этого пакета еще не был напечатан сертификационный документ.
              </>
            )}
            <br />
            После закрытия пакет нельзя будет изменить.
          </pre>
        ),
      });

      if (!isConfirmed) {
        return;
      }

      const pack = await store.completePackage(packageId);
      reset(pack);
      message.success('Пакет успешно закрыт!');
    }
  }, [packageId, store, reset, state]);

  return (
    <PageWrapper>
      <UnderlinedHeader>
        {headerText} {isEditMode && packageId && <QrCodeButton data={packageId} />}
      </UnderlinedHeader>
      <FormWrapper>
        {isEditMode && (
          <InfoBlock data={stateInfo}>
            {state && state !== PackageState.InWork && (
              <CertificateButton title={state === PackageState.ExpertiseDone ? 'Печать сертификата' : 'Просмотр сертификата'} />
            )}
          </InfoBlock>
        )}
        <Panel className="package-form__panel">
          <Form metadata={metadata} control={control} store={dictionaryStore} errors={errors} disabled={isDisabledForm}>
            <FormButtons>
              <Button.Close to={'..'} />
              {!isDisabledForm && (
                <Button.Contained onClick={handleSubmit(onSubmit)} disabled={!isDirty}>
                  Сохранить
                </Button.Contained>
              )}
              {state === PackageState.CertificatePrinted && (
                <Button.Outlined onClick={onClosePackage}>Закрыть пакет</Button.Outlined>
              )}
            </FormButtons>
          </Form>
        </Panel>
        {isEditMode && store.data && <Worksheet data={store.data} />}
      </FormWrapper>
    </PageWrapper>
  );
});
