import { FC, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ThemeProvider } from '@mui/material';
import lodash from 'lodash';
import { MobXProviderContext, observer } from 'mobx-react';

import { FormWrapper, PageWrapper, Panel, Placeholder, Table, UnderlinedHeader } from '../../components';
import { OrderState } from '../../constants';
import { Stores } from '../../stores';
import { IOrder } from '../../view-models';
import { WhiteGreyTableTheme } from '../common';

import { metadata } from './metadata';
import { Packages } from './packages';

import './orders-list.css';

export const OrdersList: FC = observer(() => {
  const { ordersListStore: store, orderFormStore } = useContext(MobXProviderContext) as Stores;
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);

  const loadData = useCallback(async () => {
    try {
      setIsLoading(!store.data.length);
      await store.init();
    } finally {
      setIsLoading(false);
    }
  }, [setIsLoading]);

  useEffect(() => {
    loadData();
  }, [store, loadData]);

  const onDoubleClick = useCallback((order: IOrder) => {
    navigate(`/orders/${order?.id}`);
  }, []);

  const onClick = useCallback((order: IOrder) => {
    orderFormStore.fetch(order.id);
    store.onSelectRow(order);
  }, []);

  const totalInfo = useMemo(() => {
    return {
      totalCount: store.data.length,
      inWorkCount: lodash.filter(store.data, { state: OrderState.InWork }).length ?? 0,
      DoneCount: lodash.filter(store.data, { state: OrderState.Done }).length ?? 0,
    };
  }, [store.data.length]);

  let body = <Placeholder className="order-list__placeholder">Нет заявок для отображения</Placeholder>;

  if (isLoading) {
    body = <Placeholder className="order-list__placeholder" isLoading />;
  } else if (store.data.length > 0) {
    body = (
      <>
        <Panel className="order-list__panel">
          <ThemeProvider theme={WhiteGreyTableTheme}>
            <Table
              metadata={metadata}
              data={store.data}
              keyField="id"
              onDoubleClickRow={onDoubleClick}
              onClickRow={onClick}
              selected={store.selectedOrder}
            />
          </ThemeProvider>
          <div className="order-list__total-row">
            <span>Всего:</span>
            <span>{`заявок: ${totalInfo.totalCount}, в работе: ${totalInfo.inWorkCount}, завершено: ${totalInfo.DoneCount}`}</span>
          </div>
        </Panel>
        {store.selectedOrder && <Packages key="packages" />}
      </>
    );
  }
  return (
    <PageWrapper>
      <UnderlinedHeader>Заявки</UnderlinedHeader>
      <FormWrapper>{body}</FormWrapper>
    </PageWrapper>
  );
});
