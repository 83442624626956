import { PurposeType } from '../constants';
import { IPlottingDTO } from '../models';
import { FilesStore } from '../stores/files-store';
import { ContentType, IApi } from '../utils';
import { IPackage, IPlotting } from '../view-models';

export class PlottingService {
  public constructor(private api: IApi) {}

  public async get(packageId: IPackage['packageId']): Promise<IPlotting> {
    const dataRes = await this.api.get({
      url: `package/${packageId}/${PurposeType.ForExpertise}`,
    });

    const data: IPlottingDTO = dataRes?.body?.worksheet?.workItems?.plotting;

    return data;
  }

  public async save(
    packageId: IPackage['packageId'],
    updated: IPlotting & { file?: ReturnType<FilesStore['get']> },
    original?: IPlotting
  ): Promise<IPlotting | null> {
    const { file } = updated;

    let res = null;
    if (original?.fileIdentity !== updated.fileIdentity) {
      res = await this.api.del({
        url: `worksheet/plotting/${packageId}`,
      });
    }

    if (file) {
      res = await this.api.post({
        url: `worksheet/plotting/${packageId}`,
        headers: {
          'Content-Type': ContentType.FormData,
        },
        body: {
          file,
        },
      });
    }

    return res?.body?.workItems?.plotting;
  }

  public async finalize(packageId: IPackage['packageId']): Promise<IPlotting> {
    const res = await this.api.put({
      url: `worksheet/plotting/finalize/${packageId}`,
    });

    return res.body?.workItems?.plotting;
  }
}
