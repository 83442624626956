import { PurposeType } from '../constants';
import { IPlottingDTO } from '../models';
import { FilesStore } from '../stores/files-store';
import { ContentType, IApi } from '../utils';
import { IPackage, IShooting } from '../view-models';

export class ShootingService {
  public constructor(private api: IApi) {}

  public async get(packageId: IPackage['packageId']): Promise<IShooting> {
    const res = await this.api.get({
      url: `package/${packageId}/${PurposeType.ForEngravingShooting}`,
    });

    const data: IPlottingDTO = res?.body?.worksheet?.workItems?.shooting;

    return data;
  }

  public async save(
    packageId: IPackage['packageId'],
    updated: IShooting & { mainFile?: ReturnType<FilesStore['get']>; otherFiles?: Array<ReturnType<FilesStore['get']>> },
    original?: IShooting
  ) {
    let res = null;

    if (updated.mainPicture !== original?.mainPicture) {
      if (updated.mainPicture) {
        res = await this.api.post({
          url: `worksheet/shooting-upload/${packageId}`,
          headers: {
            'Content-Type': ContentType.FormData,
          },
          body: {
            main: updated.mainFile,
          },
        });
      }
    }

    const existedFiles = original?.otherMedia || [];
    const currentFiles = updated?.otherMedia || [];

    const filesToDelete = original?.otherMedia?.filter((f) => !currentFiles?.includes(f));
    const filesToAdd = updated?.otherMedia
      ?.filter((f) => !existedFiles.includes(f))
      .map((f) => updated.otherFiles?.find((file) => file instanceof File && file.name === f));

    if (filesToDelete?.length) {
      res = await this.api.del({
        url: `worksheet/shooting-other-media/${packageId}`,
        body: filesToDelete,
      });
    }

    if (filesToAdd?.length) {
      res = await this.api.put({
        url: `worksheet/shooting-upload-other-media/${packageId}`,
        headers: {
          'Content-Type': ContentType.FormData,
        },
        body: {
          other: filesToAdd,
        },
      });
    }

    return res?.body?.workItems?.shooting;
  }

  public async finalize(packageId: IPackage['packageId']): Promise<IShooting> {
    const res = await this.api.put({
      url: `worksheet/shooting/finalize/${packageId}`,
    });

    return res.body?.workItems?.shooting;
  }
}
