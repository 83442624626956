import { ChangeEvent, DragEvent, FC, useCallback, useContext } from 'react';
import AddIcon from '@mui/icons-material/Add';
import lodash from 'lodash';
import { MobXProviderContext, observer } from 'mobx-react';

import { Button, ImageViewer } from '../../../../components';
import { Stores } from '../../../../stores';
import { IShooting } from '../../../../view-models';

import { FilesList } from './files-list';

interface IAddDiagnosticFilesProps {
  value?: IShooting['mainPicture'] | undefined;
  onChange: (data: IShooting) => void;
  onClick?: (file: string) => void;
  otherMedia?: IShooting['otherMedia'];
  disabled?: boolean;
}

export const ShootingFiles: FC<IAddDiagnosticFilesProps> = observer((props) => {
  const { value, onChange, disabled, otherMedia, onClick } = props;
  const { filesStore: store } = useContext(MobXProviderContext) as Stores;

  const AddMainFile = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      try {
        const file = Array.from(event.target.files ?? [])[0];
        if (!file.name.match(/\.(jpg|jpeg$)/)) {
          throw new Error('Недопустимый формат файла');
        }

        store.set(file.name, file);
        onChange({
          mainPicture: file.name,
          otherMedia,
        });
      } finally {
        event.target.value = '';
      }
    },
    [store, onChange, otherMedia]
  );

  const AddOtherMediaFiles = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      try {
        const files = Array.from(event.target.files ?? []);
        if (files.length + (otherMedia?.length ?? 0) > 10) {
          throw new Error('Дополнительных файлов должно быть не больше 10');
        }

        if (files.some((f) => !f.name.match(/\.(mp4|jpg|jpeg|html)$/))) {
          throw new Error('Файлы должны быть форматов .mp4, .jpg/jpeg, .html');
        }

        files.forEach((file: File) => {
          store.set(file.name, file);
        });

        onChange({
          mainPicture: value,
          otherMedia: lodash.uniq([...(otherMedia ?? []), ...files.map((file) => file.name)]),
        });
      } finally {
        event.target.value = '';
      }
    },
    [onChange, store, value, otherMedia]
  );

  const onDeleteOtherMedia = useCallback(
    (file: string) => {
      onChange({
        mainPicture: value,
        otherMedia: otherMedia?.filter((f) => f !== file),
      });
    },
    [onChange, value, otherMedia]
  );

  const onDropFiles = useCallback(
    (event: DragEvent<HTMLDivElement>) => {
      const files = Array.from(event.dataTransfer?.files ?? []);

      if (files.length + (otherMedia?.length ?? 0) > 10) {
        throw new Error('Дополнительных файлов должно быть не больше 10');
      }

      if (files.some((f) => !f.name.match(/\.(mp4|jpg|jpeg|html)$/))) {
        throw new Error('Файлы должны быть форматов .mp4, .jpg/jpeg, .html');
      }

      files.forEach((file: File) => {
        store.set(file.name, file);
      });

      onChange({
        mainPicture: value,
        otherMedia: lodash.uniq([...(otherMedia ?? []), ...files.map((file) => file.name)]),
      });
    },
    [otherMedia, store, value]
  );

  return (
    <div className={'shooting-files'}>
      {value ? <ImageViewer key={value} fileName={value} /> : <div />}
      <FilesList
        fileNames={otherMedia}
        onDelete={onDeleteOtherMedia}
        disabled={disabled}
        onClick={onClick}
        onDrop={onDropFiles}
      />
      {!disabled ? (
        <>
          <Button.Upload startIcon={<AddIcon />} onChange={AddMainFile}>
            Изображение
          </Button.Upload>
          <Button.Upload
            startIcon={<AddIcon />}
            onChange={AddOtherMediaFiles}
            multiple
            disabled={!value || (otherMedia?.length ?? 0) > 10}
          >
            Файлы
          </Button.Upload>
        </>
      ) : null}
    </div>
  );
});
