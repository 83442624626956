import { action, makeObservable, observable } from 'mobx';

import { Services } from '../services';
import { ICertificate, IPackage } from '../view-models';

export class CertificateStore {
  @observable public errors: Record<string, string> | undefined = undefined;

  public constructor(private services: Services) {
    makeObservable(this);
  }

  @action
  public fetch = async (packageId: string): Promise<ICertificate> => {
    const res = await this.services.certificate.get(packageId);
    return res;
  };

  public save = async (packageId: IPackage['packageId'], documentNumber: string) => {
    await this.services.certificate.save(packageId, documentNumber);
  };

  public print = async (packageId: IPackage['packageId']) => {
    let res;
    try {
      res = await this.services.certificate.getFile(packageId);
    } catch (error) {
      console.error(error);
      throw new Error('Произошла ошибка. Попробуйте еще раз');
    }

    window.open(URL.createObjectURL(res), '_blank');
  };

  public confirmPrint = async (packageId: IPackage['packageId']): Promise<ICertificate> => {
    try {
      return await this.services.certificate.confirmPrint(packageId);
    } catch (error) {
      console.error(error);
      throw new Error('Произошла ошибка. Попробуйте еще раз');
    }
  };

  @action
  public finalize(): void {
    this.errors = undefined;
  }
}
