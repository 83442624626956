import { PackageType, PurposeType } from '../constants';
import { IMeasurementDTO } from '../models';
import { FilesStore } from '../stores/files-store';
import { ContentType, IApi } from '../utils';
import { IMeasurement, IPackage } from '../view-models';

export class MeasurementService {
  public constructor(private api: IApi) {}

  public async get(packageId: IPackage['packageId']): Promise<IMeasurement> {
    const res = await this.api.get({
      url: `package/${packageId}/${PurposeType.ForExpertise}`,
    });

    const data: IMeasurementDTO = res?.body?.worksheet?.workItems?.measurement;

    return data;
  }

  public async save(
    pack: IPackage,
    updated: IMeasurement & { file?: ReturnType<FilesStore['get']> },
    original?: IMeasurement
  ): Promise<IMeasurement> {
    //todo решить проблему с числовым input-ом
    const data = Object.entries(updated).reduce((prev, [key, value]) => ({ ...prev, [key]: Number(value) }), {});
    const file = updated.file;

    let res = null;
    switch (pack.type) {
      case PackageType.SingleStone: {
        if (original?.fileIdentity !== updated.fileIdentity) {
          res = await this.api.del({
            url: `worksheet/measure/${pack.packageId}`,
          });
        }

        if (updated.fileIdentity && file) {
          res = await this.api.post({
            url: `worksheet/helium-measure-singlestone-upload/${pack.packageId}`,
            headers: {
              'Content-Type': ContentType.FormData,
            },
            body: {
              file,
            },
          });
        } else {
          res = await this.api.put({
            url: 'worksheet/measure-singlestone',
            body: {
              packageId: pack.packageId,
              ...data,
            },
          });
        }

        break;
      }
      case PackageType.MultipleStones: {
        res = await this.api.put({
          url: 'worksheet/measure-multiplestones',
          body: {
            packageId: pack.packageId,
            ...data,
          },
        });

        break;
      }
    }

    return res?.body?.workItems?.measurement;
  }

  public async finalize(packageId: IPackage['packageId']): Promise<IMeasurement> {
    const res = await this.api.put({
      url: `worksheet/measure/finalize/${packageId}`,
    });

    return res.body?.workItems?.measurement;
  }
}
