import { createTheme } from '@mui/material/styles';

export const theme = createTheme({
  components: {
    MuiTableRow: {
      styleOverrides: {
        root: {
          color: '#ffffff',
          '&:nth-of-type(odd)': {
            backgroundColor: '#a0907b',
          },
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          backgroundColor: 'inherit',
          padding: '10px',
          fontSize: '14px',
          lineHeight: '19px',
          color: 'inherit',
          borderBottom: 'none',
        },
        head: {
          backgroundColor: '#877358',
          color: '#ffffff',
          whiteSpace: 'nowrap',
        },
      },
    },
  },
});
