import { ChangeEvent, FC, ReactNode } from 'react';
import { Controller, ControllerProps } from 'react-hook-form';
import { ControllerRenderProps } from 'react-hook-form/dist/types/controller';
import { FieldErrors } from 'react-hook-form/dist/types/errors';
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import { FormControl, FormHelperText } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import * as lodash from 'lodash';

import { DictionaryStore } from '../../../stores/dictionary-store';
import { FieldOptions, IDictionaryItem } from '../../../view-models';
import { FieldType } from '../field-type';

import { FieldCheckbox } from './fields/field-checkbox';
import { FieldCheckboxGroup } from './fields/field-checkbox-group';
import { FieldPassword } from './fields/field-password';
import { FieldRadio } from './fields/field-radio';
import { FieldSelect } from './fields/field-select';
import { FieldText } from './fields/field-text';

import './field.css';

export interface IFieldProps
  extends Pick<ControllerProps, 'control' | 'rules' | 'disabled'>,
    Partial<Pick<ControllerRenderProps, 'onChange'>> {
  /** Идентификатор поля для React-а. В слечае отсутствия берется name. Надо чтобы к одному полю данных можно было привязать две компоненты */
  id?: string;
  className?: string;
  description?: string;
  errors?: FieldErrors<Record<string, any>>;
  label?: string;
  name: string;
  options?: FieldOptions;
  placeholder?: string;
  store?: DictionaryStore;
  type?: FieldType;
  url?: string;
  isFieldHide?: (item: IDictionaryItem) => boolean;
  template?: (props?: any) => ReactNode;
}

export const Field: FC<IFieldProps> = (props) => {
  const { className, control, errors, label, name, rules, template, type, ...restProps } = props;

  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({ field, formState }) => {
        const currentError = lodash.get(errors, name);

        const fieldProps = {
          ...field,
          ...restProps,
          error: !!currentError,
          required: !!rules?.required,
        };

        let component;

        switch (type) {
          case FieldType.DATE: {
            let value = field.value;
            if (value && !dayjs.isDayjs(value)) {
              value = dayjs(value);
            }

            component = (
              <DatePicker
                {...fieldProps}
                value={value}
                slotProps={{
                  textField: fieldProps,
                }}
                slots={{
                  openPickerIcon: () => <CalendarMonthOutlinedIcon classes={{ root: 'field__calendar' }} />,
                }}
              />
            );
            break;
          }

          case FieldType.RADIO: {
            component = <FieldRadio {...lodash.omit(fieldProps, 'error')} />;
            break;
          }

          case FieldType.CHECKBOX_GROUP: {
            const changeHandler = (itemValue: any, event: ChangeEvent<HTMLInputElement>): void => {
              // new Set надо делать чтобы поменять ссылку на множество и тем самым сказать react-hook-form что поле изменилось
              const currentSet: Set<any> = new Set(fieldProps.value ?? []);
              if (event.target.checked) {
                currentSet.add(itemValue);
              } else {
                currentSet.delete(itemValue);
              }

              const defaultValue = lodash.get(formState.defaultValues, fieldProps.name);
              const isShouldDirty = !lodash.isEqual(defaultValue, currentSet);

              const value = isShouldDirty ? currentSet : defaultValue;
              field.onChange(value.size === 0 ? null : value);
            };

            component = <FieldCheckboxGroup {...lodash.omit(fieldProps, ['error', 'onChange'])} onChange={changeHandler} />;
            break;
          }

          case FieldType.CHECKBOX: {
            component = <FieldCheckbox {...lodash.omit(fieldProps, ['error'])} />;
            break;
          }

          case FieldType.SELECT: {
            component = <FieldSelect {...fieldProps} />;
            break;
          }

          case FieldType.PASSWORD: {
            component = <FieldPassword {...fieldProps} />;
            break;
          }

          case FieldType.CUSTOM: {
            component = template?.(fieldProps);
            break;
          }

          default: {
            component = <FieldText {...fieldProps} />;
          }
        }

        return (
          <FormControl required={!!rules?.required} error={!!currentError} classes={{ root: className }}>
            {component}
            {currentError && <FormHelperText className="field__helper-text">{currentError?.message as string}</FormHelperText>}
          </FormControl>
        );
      }}
    />
  );
};
