import { FC, useCallback, useContext, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { ThemeProvider } from '@mui/material';
import lodash from 'lodash';
import { MobXProviderContext, observer } from 'mobx-react';

import { Table } from '../../../components';
import { OrderState } from '../../../constants';
import { Stores } from '../../../stores';
import { IPackage } from '../../../view-models';

import { AddPackageButton } from './add-package-button';
import { metadata } from './metadata';
import { theme } from './theme';

import './packages.css';

interface IShortPackage extends Pick<IPackage, 'packageId' | 'number' | 'quantityFact' | 'weightFact' | 'state'> {}

export const Packages: FC = observer(() => {
  const { orderFormStore: store } = useContext(MobXProviderContext) as Stores;
  const {
    id: orderId,
    packages = [],
    state: orderState,
  } = store.data ?? { packages: [] as IShortPackage[], id: null, state: null };
  const navigate = useNavigate();

  const totalInfo = useMemo(() => {
    const info = {
      totalCount: packages?.length,
      inWorkCount: lodash.sumBy(packages, 'quantityFact') ?? 0,
      DoneCount: lodash.sumBy(packages, 'weightFact') ?? 0,
    };
    return `пакетов: ${info.totalCount}, камней: ${info.inWorkCount}, ${info.DoneCount} ct`;
  }, [packages?.length]);

  const onDoubleClick = useCallback(
    (pack: IShortPackage) => {
      navigate(`/orders/${orderId}/packages/${pack?.packageId}`);
    },
    [navigate, orderId]
  );

  return (
    <div className="order-packages">
      <h2 className="order-packages__packages-header">Пакеты</h2>
      {packages?.length > 0 && (
        <div className="order-packages__table-block">
          <ThemeProvider theme={theme}>
            <Table metadata={metadata} data={packages} keyField="packageId" onDoubleClickRow={onDoubleClick} />
          </ThemeProvider>
          <div className="order-packages__total-row">
            <span>Всего:</span>
            <span className="order-packages__total-info" title={totalInfo}>
              {totalInfo}
            </span>
          </div>
        </div>
      )}

      {orderState && orderState !== OrderState.Done && <AddPackageButton orderId={orderId} />}
    </div>
  );
});
