import { action, makeObservable, observable, runInAction } from 'mobx';

import { Services } from '../services';
import { downloadFile } from '../utils';
import { IOrder } from '../view-models';

export class OrderFormStore {
  @observable public data: IOrder | null = null;

  public constructor(private services: Services) {
    makeObservable(this);
  }

  @action
  public fetch = async (orderId: string): Promise<IOrder> => {
    if (this.data && `${this.data?.id}` === `${orderId}`) {
      return this.data;
    }

    const res = await this.services.orders.getById(orderId);
    runInAction(() => {
      this.data = observable(res);
    });

    return res;
  };

  @action
  public finalize = (): void => {
    this.data = null;
  };

  public create = async (newOrder: Partial<IOrder>): Promise<IOrder['id']> => {
    return await this.services.orders.create(newOrder);
  };

  public update = async (order: Partial<IOrder>): Promise<void> => {
    await this.services.orders.update(order);
    runInAction(() => {
      if (this.data) {
        // Обновляем запись так как ипользуется например в залоголовке подчеркнутом зеленой линией
        this.data = { ...this.data, ...order };
      }
    });
  };

  public downloadDocuments = async (): Promise<void> => {
    const res = await this.services.orders.getDocuments(this.data as IOrder);
    res.forEach((file) => {
      if (file?.file) {
        downloadFile(file.file, file.filename ?? '');
      }
    });
  };

  public completeOrder = async (orderId: IOrder['id']): Promise<IOrder> => {
    return await this.services.orders.complete(orderId);
  };
}
