import { ChangeEventHandler, forwardRef, ForwardRefExoticComponent } from 'react';
import { Checkbox, FormControlLabel } from '@mui/material';
import { observer } from 'mobx-react';

export const FieldCheckbox: ForwardRefExoticComponent<
  Omit<
    {
      value: boolean;
      disabled?: boolean;
      onChange?: ChangeEventHandler<HTMLInputElement>;
    },
    'ref'
  >
> = observer(
  forwardRef((props, ref) => {
    const { disabled, value, ...restProps } = props;

    return <FormControlLabel control={<Checkbox checked={value ?? false} {...restProps} />} label={'Да'} disabled={disabled} />;
  })
);
